import "../styles/style.scss";
import { initApp } from "./app.js";
import { gsap } from 'gsap';

// document.addEventListener('DOMContentLoaded', () => {
//   const canvas = document.querySelector('.webgl');
//   if (!canvas) {
//     console.error('Canvas element not found');
//     return;
//   }

//   initApp(canvas);

// });

// function updateThreeJSScene(index) {
//   gsap.to(camera.position, {
//       duration: 1.5,
//       ease: 'power2.inOut',
//       y: -objectsDistance * index
//   });

//   gsap.to(sectionMeshes[index].rotation, {
//       duration: 1.5,
//       ease: 'power2.inOut',
//       x: '+=6',
//       y: '+=3',
//       z: '+=1.5'
//   });
// }

const menuMobileButton = document.querySelector("#header-nav-menu-button");
menuMobileButton.addEventListener("click", function () {
  this.classList.toggle("active");
  const elementsToToggle = document.querySelectorAll("#menu-mobile");
  elementsToToggle.forEach((element) => {
    element.classList.toggle("hidden");
  });
});

const worldwide = document.querySelector(".worldwide");
if (worldwide) {
  new PureCounter({
    selector: ".worldwide",
    start: 6820000000,
    end: 6830000000,
    duration: 3,
    delay: 10,
    once: true,
    repeat: false,
    decimals: 0,
    legacy: true,
    filesizing: false,
    currency: false,
    separator: true,
  });
}

// window.addEventListener('load', () => {
//   const preloader = document.getElementById('preloader');
//   if (preloader) {
//     preloader.style.display = 'none';
//   }
// });

const backgroundVideo = document.getElementById("backgroundVideo");
const closeButton = document.getElementById("video-overlay__close-btn");
let backgroundSound;

if (backgroundVideo) {
  backgroundVideo.addEventListener("click", () => {
    backgroundVideo.currentTime = 0; // Play from the start
    backgroundVideo.muted = false;
    backgroundVideo.play();

    // Pause the background sound
    if (backgroundSound) {
      backgroundSound.pause();
    }

    if (backgroundVideo.requestFullscreen) {
      backgroundVideo.requestFullscreen();
    } else if (backgroundVideo.mozRequestFullScreen) {
      // Firefox
      backgroundVideo.mozRequestFullScreen();
    } else if (backgroundVideo.webkitRequestFullscreen) {
      // Chrome, Safari, and Opera
      backgroundVideo.webkitRequestFullscreen();
    } else if (backgroundVideo.msRequestFullscreen) {
      // IE/Edge
      backgroundVideo.msRequestFullscreen();
    }
  });

  // Listen for fullscreen changes
  const handleFullscreenChange = () => {
    if (
      document.fullscreenElement ||
      document.mozFullScreenElement ||
      document.webkitFullscreenElement ||
      document.msFullscreenElement
    ) {
      closeButton.style.display = "block";
    } else {
      closeButton.style.display = "none";
      backgroundVideo.muted = true; // Mute the video when exiting fullscreen
      // Resume the background sound when exiting fullscreen
      if (backgroundSound) {
        backgroundSound.play();
      }
    }
  };

  document.addEventListener("fullscreenchange", handleFullscreenChange);
  document.addEventListener("mozfullscreenchange", handleFullscreenChange);
  document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
  document.addEventListener("msfullscreenchange", handleFullscreenChange);

  // Close button event listener to exit fullscreen
  closeButton.addEventListener("click", () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      // Firefox
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      // Chrome, Safari and Opera
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE/Edge
      document.msExitFullscreen();
    }

    // Resume the background sound when exiting fullscreen
    if (backgroundSound) {
      backgroundSound.play();
    }

    backgroundVideo.muted = true; // Mute the video when exiting fullscreen
  });
} else {
  console.error("Video element not found");
}

const section = document.querySelector("#section-16");
function animateLogos() {
  const logos = document.querySelectorAll("#section-16 .logo");
  const sectionWidth = section.clientWidth;
  const sectionHeight = section.clientHeight;

  const centerX = sectionWidth / 2;
  const centerY = sectionHeight / 2;

  logos.forEach((logo, index) => {
    // Set a random initial position outside the center area
    const angle = Math.random() * 2 * Math.PI;
    const distance = Math.max(sectionWidth, sectionHeight) / 2; // Ensure logos start from edges
    const startX = centerX + Math.cos(angle) * distance;
    const startY = centerY + Math.sin(angle) * distance;

    // Set initial position and make sure the logo is visible within the section
    gsap.set(logo, {
      left: Math.max(0, Math.min(startX, sectionWidth - logo.offsetWidth)),
      top: Math.max(0, Math.min(startY, sectionHeight - logo.offsetHeight)),
      opacity: 1,
      // duration: 0.1
    });

    // Create a timeline for each logo
    const tl = gsap.timeline({
      repeat: -1,
      //repeatDelay: 0.2,
      delay: Math.random() * 2, // Random delay for each logo's animation start
    });

    tl.to(logo, {
      //opacity: 1,
      duration: 10,
      // x: centerX - logo.offsetWidth / 2,  // Center X (accounting for logo size)
      // y: centerY - logo.offsetHeight / 2, // Center Y (accounting for logo size)
      top: centerY - logo.offsetWidth * 0.2, // Center Y (accounting for logo size)
      left: centerX + (Math.random() * 2 - 1) * (sectionWidth / 4), // Move left or right randomly
      stagger: 0.3,
      ease: "power2.Out",
    }).set(logo, {
      opacity: 0,
      display: "none", // Hide logo after animation,
      delay: 3,
    });
  });
}

// Call the animate function on page load
if (section) {
  window.addEventListener("load", animateLogos);
}

const timelineItems = document.querySelectorAll('.timeline-item');
const contentItems = document.querySelectorAll('.timeline-slide .item');

timelineItems.forEach(item => {
  item.addEventListener('click', () => {
    const targetId = item.id.replace('toggle-', '');

    contentItems.forEach(content => {
      content.classList.remove('active');
      if (content.id === targetId) {
        content.classList.add('active');
      }
    });
  });
});

document.addEventListener("DOMContentLoaded", function () {
  document.querySelectorAll('[class^="intro-content-"]').forEach(content => {
    content.style.maxHeight = content.scrollHeight + "px";
  });
});

document.querySelectorAll('.intro-btn').forEach(button => {
  button.addEventListener('click', function (event) {
    event.preventDefault();

    let relValue = this.getAttribute('rel');
    let section = document.getElementById(`section-${relValue}`);
    let listAnimate = section.querySelector(`.intro-content-${relValue}`);

    let isCollapsed = this.classList.contains('collapsed');

    if (isCollapsed) {
      listAnimate.style.maxHeight = listAnimate.scrollHeight + 'px';
      listAnimate.style.opacity = '1';
    } else {
      listAnimate.style.maxHeight = '0px';
      listAnimate.style.opacity = '0';
    }

    this.innerHTML = isCollapsed 
      ? 'Collapse <img src="images/collapse.png" width="20">' 
      : 'Expand <img src="images/expand.png" width="20">';

    this.classList.toggle('collapsed');
  });
});




