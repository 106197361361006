import * as THREE from "three";
import { createNoise2D } from "simplex-noise";

const noise2D = createNoise2D();
let time = 0;

function createGridTexture(size, gridSize) {
  const canvas = document.createElement("canvas");
  canvas.width = size;
  canvas.height = size;
  const context = canvas.getContext("2d");

  context.strokeStyle = "rgba(255, 255, 255, 0.5)";
  context.lineWidth = 1;

  for (let i = 0; i <= size; i += gridSize) {
    context.moveTo(i, 0);
    context.lineTo(i, size);
    context.stroke();
    context.moveTo(0, i);
    context.lineTo(size, i);
    context.stroke();
  }

  return new THREE.CanvasTexture(canvas);
}

const gridTexture = createGridTexture(4000, 30);

const gridVertexShader = `
  varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;

  void main() {
    vUv = uv;
    vNormal = normalize(normalMatrix * normal);
    vPosition = (modelViewMatrix * vec4(position, 1.0)).xyz;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
  }
`;

const gridFragmentShader = `
  varying vec2 vUv;
  varying vec3 vNormal;
  varying vec3 vPosition;

  uniform vec3 lightPosition;
  uniform vec3 lightColor;
  uniform vec3 ambientLight;
  uniform vec3 fogColor;
  uniform float fogNear;
  uniform float fogFar;
  uniform sampler2D gridTexture;
  uniform vec3 color;
  uniform float time;
  uniform float BloomIntensityFactor;
  uniform vec3 pondColor;

  float random(vec2 co) {
    return fract(sin(dot(co.xy, vec2(12.9898, 78.233))) * 43758.5453);
  }

  void main(){
    vec4 gridColor = texture2D(gridTexture, vUv);
    vec3 finalColor = mix(color, gridColor.rgb, gridColor.a);

    vec3 normal = normalize(vNormal);
    vec3 lightDir = normalize(lightPosition - vPosition);
    float diff = max(dot(normal, lightDir), 0.0);

    vec3 viewDir = normalize(-vPosition);
    vec3 reflectDir = reflect(-lightDir, normal);
    float spec = pow(max(dot(viewDir, reflectDir), 0.0), 32.0);

    vec3 ambient = ambientLight;
    vec3 diffuse = lightColor * diff;
    vec3 specular = lightColor * spec;

    vec3 result = (ambient + diffuse + specular) * finalColor;

    // Add light blooming effect
    float pondGlow = exp(-pow((vUv.x - 0.5) * 40.0, 2.0)) * .6;
    vec3 pondLight = pondColor * pondGlow  ;
    
    result += pondLight;
    float bloom = pow(max(dot(lightDir, normal), 0.0), 16.0);
    result += bloom * lightColor * BloomIntensityFactor ;

    // Apply fog
    float depth = length(vPosition);
    float fogFactor = smoothstep(fogNear, fogFar, depth);
    vec3 color = mix(result, fogColor, fogFactor);

    gl_FragColor = vec4(color, 1.0);
  }
`;

const customShaderMaterial = new THREE.ShaderMaterial({
  vertexShader: gridVertexShader,
  fragmentShader: gridFragmentShader,
  uniforms: {
    time: { value: 0 },
    gridTexture: { value: gridTexture },
    color: { value: new THREE.Color(0x222222) },
    lightPosition: { value: new THREE.Vector3(50, 50, 50) },
    lightColor: { value: new THREE.Color(0x8a2be2) },
    ambientLight: { value: new THREE.Color(0x4b0082) },
    pondColor: { value: new THREE.Color(1.0, 0.3, 1.0) },
    BloomIntensityFactor: { value: 1.25 },
    fogColor: { value: new THREE.Vector3(0.0, 0.0, 0.0) },
    fogNear: { value: 30.0 },
    fogFar: { value: 350.0 },
  },
  wireframe: false,
  transparent: true,
  side: THREE.FrontSide
});

function generateHeightMap(terrainWidth, terrainHeight, startY, endY, initialEdgeHeights) {
  const size = terrainWidth * terrainHeight;
  const heightData = new Float32Array(size);
  const scale = 0.01;
  const chasmDepth = -2.5;
  const bumpHeight = 35;
  const smoothFactor = 0.1;
  const smallScaleAmplitude = 0.5;
  const tinyScaleAmplitude = 0.8;
  const smallScaleFrequency = 5;
  const tinyScaleFrequency = 0.5;

  for (let y = 0; y < terrainHeight; y++) {
    for (let x = 0; x < terrainWidth; x++) {
      const i = y * terrainWidth + x;

      const distFromCenter = Math.abs(x - terrainWidth / 2);
      const maxDistance = terrainWidth / 2;

      const noiseValue = noise2D(x * scale, (y + startY) * scale);
      const noiseValueSmallScale = noise2D(x * scale * smallScaleFrequency, (y + startY) * scale * smallScaleFrequency) * smallScaleAmplitude;
      const noiseValueTinyScale = noise2D(x * scale * tinyScaleFrequency, (y + startY) * scale * tinyScaleFrequency) * tinyScaleAmplitude;

      const distanceFactor = distFromCenter / maxDistance;
      const bumpFactor = (1 - distanceFactor) ** 2;

      let chasmVariation = noise2D(x * scale * 2, (y + startY) * scale * 2) * 0.8;
      let height = (noiseValue + noiseValueSmallScale + noiseValueTinyScale) * bumpHeight * smoothFactor * bumpFactor;

      if (distFromCenter < terrainWidth / 20) {
        const noiseFactor = noise2D(x * scale * 8, (y + startY) * scale * 8) * 0.01;
        const noiseScale = noise2D(x * scale * 20, (y + startY) * scale * 20) * 0.05;
        height = chasmDepth + chasmVariation + noiseFactor + noiseScale;
      } else if (distFromCenter < terrainWidth / 10) {
        const transitionFactor = (distFromCenter - terrainWidth / 20) / (terrainWidth / 10 - terrainWidth / 20);
        height = THREE.MathUtils.lerp(chasmDepth + chasmVariation, height, transitionFactor);
      }

      // Use initial edge heights to ensure continuity
      if (initialEdgeHeights) {
        if (y === 0) {
          height = initialEdgeHeights[x]; // Set the first row to match the last row of the previous segment
        }
        if (x === 0) {
          height = initialEdgeHeights[0] ; // Set the first column to match the last column of the previous segment
        }
      
      }

      heightData[i] = height;
    }
  }

  return heightData;
}

function createTerrainSegment(width, height, zPosition, startY, endY, initialEdgeHeights) {
  const geometry = new THREE.PlaneGeometry(
    width,
    height,
    width - 1,
    height - 1
  );
  geometry.rotateX(-Math.PI / 2);

  const heightData = generateHeightMap(width, height, startY, endY, initialEdgeHeights);
  const vertices = geometry.attributes.position.array;

  for (let i = 0, j = 0; i < heightData.length; i++, j += 3) {
    vertices[j + 1] = heightData[i] * 10;
  }

  geometry.computeVertexNormals();
  const mesh = new THREE.Mesh(geometry, customShaderMaterial.clone());
  mesh.position.y = -2;
  mesh.position.z = zPosition;

  return { mesh, heightData };
}

export function createObject2() {
  const objectGroup = new THREE.Group();
  const terrainSegments = [];
  const segmentWidth = 600;
  const segmentHeight = 400;
  const segmentCount = 10;

  let previousSegmentHeightData = null;

  for (let i = 0; i < segmentCount; i++) {
    const { mesh, heightData } = createTerrainSegment(
      segmentWidth,
      segmentHeight,
      i  * segmentHeight,
      i * segmentHeight,
      (i + 1) * segmentHeight,
      previousSegmentHeightData
    );
    
      terrainSegments.push(mesh);
      objectGroup.add(mesh)
      previousSegmentHeightData = heightData.slice(-segmentWidth);
     // Store the last row's height data for continuity

  }

  function animateModel() {
    time += 0.05;
    if (customShaderMaterial) {
      customShaderMaterial.uniforms.time.value = time;
    }
    for (let i = 0; i < terrainSegments.length; i++) {
      terrainSegments[i].position.z += 0.4; // Adjust speed as needed
      // Reposition the segment if it goes out of view
      if (terrainSegments[i].position.z >
 segmentHeight) {
        terrainSegments[i].position.z -= segmentHeight * segmentCount;
        
      }
    }
  }

  if (!objectGroup.userData.animateFunctions) {
    objectGroup.userData.animateFunctions = [];
  }
  objectGroup.userData.animateFunctions.push(animateModel);

  objectGroup.position.set(0, -2, 0);

  objectGroup.userData.changeColor = (color) => {
    customShaderMaterial.uniforms.color.value.set(color);
    customShaderMaterial.uniforms.lightColor.value.set(color);
    customShaderMaterial.uniforms.ambientLight.value.set(color);
    customShaderMaterial.uniforms.pondColor.value.set(color);
    console.log("color");
    // color: { value: new THREE.Color(0x222222) },
    // lightPosition: { value: new THREE.Vector3(50, 50, 50) },
    // lightColor: { value: new THREE.Color(0x8a2be2) },
    // ambientLight: { value: new THREE.Color(0x4b0082) },
    // pondColor: { value: new THREE.Color(1.0, 0.3, 1.0) },
    // BloomIntensityFactor: { value: 1.25 },
    // fogColor: { value: new THREE.Vector3(0.0, 0.0, 0.0) },


  };
  return objectGroup;
}


export function animateObject2(objectGroup) {
  if (objectGroup.userData.animateFunctions) {
    objectGroup.userData.animateFunctions.forEach((fn) => fn());
  }
}
