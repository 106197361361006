import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { gsap } from 'gsap';
import GUI from 'lil-gui';
import { createBackgroundScene } from '../components/backgroundScene.js';
import { createObject1 } from '../components/object1.js';
import { createObject2, animateObject2 } from '../components/object2.js';
import { createObject3 } from '../components/object3.js';
import { handleResize } from './utils.js';
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { Howl, Howler } from 'howler';
import backgroundMusic from '/space.mp3';

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

export function initApp(canvas) {
  const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
  };

  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true, antialias: true });
  renderer.setSize(sizes.width, sizes.height);
  renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  renderer.toneMapping = THREE.ReinhardToneMapping;

  const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 1000);
  camera.position.set(0, 0, 90);

  const backgroundScene = createBackgroundScene(renderer, camera);
  
  const object1 = createObject1(camera);
  const object2 = createObject2();
  const object3 = createObject3();

  const worldGroup = new THREE.Group();
  worldGroup.add(object1);
  worldGroup.add(object2);
  worldGroup.add(object3);

  const objectsDistance = 1000;
  object1.position.y = objectsDistance;
  object2.position.y = -10;
  object3.position.y = - objectsDistance;

  backgroundScene.scene.add(worldGroup);

  const animateFunctions = [
    ...(object2.userData.animateFunctions || []),
    ...(object3.userData.animateFunctions || [])
  ];

  const clock = new THREE.Clock();
  let previousTime = 0;

  let cursor = { x: 0, y: 0 };
  let parallaxActive = true;
  let navigationActive = false;


  const composer = new EffectComposer(renderer);
  const renderScene = new RenderPass(backgroundScene.scene, camera);
  composer.addPass(renderScene);

  const bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    0.2, 0.1, 0.3
  );
  //composer.addPass(bloomPass);

  // Preloader Management
  const manager = new THREE.LoadingManager();
  manager.onStart = () => {
    console.log('Loading started');
  };

  manager.onLoad = () => {
    console.log('Loading complete');
    // Delay before hiding the preloader
    setTimeout(() => {
      document.getElementById('preloader').style.display = 'none';
    }, 3000); // 3000 milliseconds = 3 seconds
  };

  manager.onProgress = (url, itemsLoaded, itemsTotal) => {
    console.log(`Loading file: ${url}. Loaded ${itemsLoaded} of ${itemsTotal} files.`);
  };

  manager.onError = (url) => {
    console.log(`There was an error loading ${url}`);
  };

  //const loader = new GLTFLoader(manager);



  function animate() {
    requestAnimationFrame(animate);
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    animateFunctions.forEach((fn) => fn());
    animateObject2(object2, deltaTime);

    composer.render();
  }
  animate();



  // function updateGroup2Objects(progress) {
  //   const revealStep = 1 / object2.children.length;
  //   object2.children.forEach((child, index) => {
  //     const revealProgress = Math.min(Math.max((progress - index * revealStep) / revealStep, 0), 1);
  //     child.visible = revealProgress > 0;
  //     gsap.to(child.position, { y: revealProgress * 10 });
  //   });

  //   if (progress === 1) {
  //     switchCameraPosition(2);
  //   }
  // }



  // Resizes
  window.addEventListener('resize', () => {
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;

    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    composer.setSize(sizes.width, sizes.height);

    // Call resize handler if defined
    if (object2.userData.resize) {
      object2.userData.resize(sizes.width, sizes.height);
    }
  });

  //Scrolls  
  // Get the reference to the #app element
  const appElement = document.querySelector('#app');
  const scrollIndicatorBar = document.querySelector('#scroll-indicator-bar');
  
  // Variable to hold the timeout ID
  let scrollTimeout;

  // Scroll event listener for the #app element
  appElement.addEventListener('scroll', () => {
    const scrollY = appElement.scrollTop;
    const totalHeight = appElement.scrollHeight - appElement.clientHeight;
    const scrollPercentage = scrollY / totalHeight;
    const sectionHeight = appElement.clientHeight; // Each section is 100vh
    const currentSection = Math.floor(scrollY / sectionHeight) + 1;

    // console.log(scrollY);
    // console.log(scrollPercentage);
    // console.log(`Current Section: ${currentSection}`);

    // Update the translateY of the scroll indicator bar based on scroll percentage
    const translateY = (scrollPercentage * (sizes.height - scrollIndicatorBar.clientHeight)) - 0.2;
    scrollIndicatorBar.style.transform = `translateY(${translateY}px)`;

    // Show the scroll indicator bar
    document.querySelector('#scroll-indicator').style.opacity = 1;
    // Clear the previous timeout
    clearTimeout(scrollTimeout);
    // Set a new timeout to hide the scroll indicator bar after 2 seconds
    scrollTimeout = setTimeout(() => {
      document.querySelector('#scroll-indicator').style.opacity = 0;
    }, 1500);

    // Update color based on the current section
    if (currentSection >= 1 && currentSection <= 3) {
      object2.userData.changeColor(0xFFFFFF); // Default color
      //console.log("change default");
    } else if (currentSection >= 4 && currentSection <= 6) {
      object2.userData.changeColor(0xFF0000); // Red color
      //console.log("change red");
    } else if (currentSection >= 7 && currentSection <= 8) {
      switchCameraPosition(1);
      object2.userData.changeColor(0x0000FF); // Blue color
      //console.log("change blue");
    } else if (currentSection >= 13 && currentSection <= 17) {
      switchCameraPosition(2);
      object2.userData.changeColor(0xFFFF00); // Yellow color
      //console.log("change yellow");
    }
});


  // const sections = document.querySelectorAll('section');
  // const sectionPositions = [];
  // const cameraPositions = [
  //   { z: 90, y: 500 },    // Camera position for section 1
  //   { z: 90, y: 0 },    // Camera position for section 2
  //   { z: 90, y: 1000 }    // Camera position for section 3
  // ];
  // // GSAP timeline for revealing objects in sequence
  // const timeline = gsap.timeline({ paused: true });

  // timeline
  //   .to(box1.material, { opacity: 1, duration: 1, onStart: () => console.log("Box 1 shown") })
  //   .to(box2.material, { opacity: 1, duration: 1, onStart: () => console.log("Box 2 shown") }, "+=1")
  //   .to(box3.material, { opacity: 1, duration: 1, onStart: () => console.log("Box 3 shown") }, "+=1");

  // // Camera animations
  // const cameraTimeline = gsap.timeline({ paused: true });

  // cameraTimeline
  //   .to(camera.position, { z: 2, duration: 1 }, 0)
  //   .to(camera.position, { x: 2, duration: 1 }, 1)
  //   .to(camera.rotation, { y: Math.PI / 2, duration: 1 }, 2);

  // sections.forEach((section, index) => {
  //   ScrollTrigger.create({
  //     trigger: section,
  //     start: "top center",
  //     end: "bottom center",
  //     onEnter: () => {
  //       section.classList.add('active');
  //       timeline.tweenTo(index + 1);
  //       cameraTimeline.tweenTo(index + 1);
  //     },
  //     onLeaveBack: () => {
  //       section.classList.remove('active');
  //       timeline.tweenTo(index);
  //       cameraTimeline.tweenTo(index);
  //     },
  //   });
  // });

  //window.addEventListener('scroll', updateCameraPosition);

  //Scene navigations
  function switchCameraPosition(index) {
    const positions = [
      { y: objectsDistance, duration: 1 },
      { y: -10, duration: 1 },
      { y: -650, duration: 1 }
    ];

    gsap.to(camera.position, {
      y: positions[index].y,
      duration: positions[index].duration,
      ease: 'power2.inOut',
      onUpdate: () => composer.render()
    });

    gsap.to(camera.position, {
      y: positions[index].y,
      duration: positions[index].duration,
      ease: 'power2.inOut',
      onUpdate: () => composer.render()
    });

    //ScrollTrigger.refresh();
  }

  // Function to scroll to a specific section by index
  // function scrollToSection(index) {
  //   const targetId = `#section-${index}`; // Construct the section ID based on the index
  //   const targetElement = document.querySelector(targetId);

  //   if (targetElement) {
  //     gsap.to(window, {
  //       scrollTo: { y: targetElement, offsetY: 0 }, // Scroll to the element, offsetY can be adjusted if needed
  //       duration: 1, // Duration of the scroll animation
  //       ease: 'power2.inOut' // Easing function
  //     });
  //   } else {
  //     console.error(`Section with ID ${targetId} not found.`);
  //   }
  // }

  const navigationLinks = document.querySelectorAll('.anchor a');
  navigationLinks.forEach(link => {
    link.addEventListener('click', function(event) {
      event.preventDefault();
      //navigationActive = true;

      navigationLinks.forEach(link => link.classList.remove('active'));
      this.classList.add('active');

      const href = link.getAttribute('href');
      switch (href) {
        // case '#intro':
        //   switchCameraPosition(0);
        //   break;
        case '#story':
          switchCameraPosition(1);
          //scrollToSection(1);
          break;
        case '#end':
          switchCameraPosition(2);
          //scrollToSection(8);
          break;
        default:
          break;
      }

      // Set a timeout to re-enable parallax after navigation (if needed)
      setTimeout(() => {
        navigationActive = false;
      }, 1000); // Adjust timing as necessary
    });
  });

  // Mouse move event listener with parallaxActive check
  // document.addEventListener('mousemove', (event) => {
  //   if (!navigationActive) { // Check if navigation is not active
  //     cursor.x = (event.clientX / sizes.width) - 0.005;
  //     cursor.y = -(event.clientY / sizes.height) + 0.005;

  //     const parallaxX = cursor.x * 10;
  //     const parallaxY = cursor.y * 10;

  //     camera.position.x += (parallaxX - camera.position.x) * 0.05;
  //     camera.position.y += (parallaxY - camera.position.y) * 0.05;
  //   }
  // });

  // Function to interpolate between terrain colors
  function interpolateColor(color1, color2, factor) {
    const result = color1.clone().lerp(color2, factor);
    return result;
  }

   // Initialize Howler global settings (if needed)
   Howler.volume(0.3); // Adjust volume if necessary

   let backgroundSound;
 
    
  // Function to initialize and play background music
  const startBackgroundMusic = () => {
    if (!backgroundSound) {
      backgroundSound = new Howl({
        src: [backgroundMusic],
        autoplay: true,
        loop: true,
        volume: 0.5, // Adjust volume if necessary
      });
    }
  };

  // Event listener for the first user gesture (e.g., click)
  let isFirstInteraction = true;
  document.addEventListener('click', () => {
    if (isFirstInteraction) {
      startBackgroundMusic();
      isFirstInteraction = false; // Disable subsequent music starts
    }
  });

  // Event listener for full screen video & sound manage
  const backgroundVideo = document.getElementById('backgroundVideo');
  const closeButton = document.getElementById('video-overlay__close-btn');

  if (backgroundVideo) {
    backgroundVideo.addEventListener('click', () => {
      backgroundVideo.currentTime = 0; // Play from the start
      backgroundVideo.muted = false;
      backgroundVideo.play();
  
      // Pause the background sound
      if (backgroundSound) {
        backgroundSound.pause();
      }
  
      if (backgroundVideo.requestFullscreen) {
        backgroundVideo.requestFullscreen();
      } else if (backgroundVideo.mozRequestFullScreen) { // Firefox
        backgroundVideo.mozRequestFullScreen();
      } else if (backgroundVideo.webkitRequestFullscreen) { // Chrome, Safari, and Opera
        backgroundVideo.webkitRequestFullscreen();
      } else if (backgroundVideo.msRequestFullscreen) { // IE/Edge
        backgroundVideo.msRequestFullscreen();
      }
    });
  
    // Listen for fullscreen changes
    const handleFullscreenChange = () => {
      if (document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        closeButton.style.display = 'block';
      } else {
        closeButton.style.display = 'none';
        backgroundVideo.muted = true; // Mute the video when exiting fullscreen
        // Resume the background sound when exiting fullscreen
        if (backgroundSound) {
          backgroundSound.play();
        }
      }
    };
  
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('msfullscreenchange', handleFullscreenChange);
  
    // Close button event listener to exit fullscreen
    closeButton.addEventListener('click', () => {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { // Firefox
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { // IE/Edge
        document.msExitFullscreen();
      }
  
      // Resume the background sound when exiting fullscreen
      if (backgroundSound) {
        backgroundSound.play();
      }
  
      backgroundVideo.muted = true; // Mute the video when exiting fullscreen
    });
  } else {
    console.error('Video element not found');
  }

  //Animate items
  // Animate elements in section-1
  //ScrollTrigger.refresh();
  // const tl3 = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: '#section-3',
  //     scroller: "#app",
  //     start: 'top 50%',
  //     end: 'bottom bottom',
  //     toggleActions: 'play none none reverse',
  //     markers: true, // Debugging markers,
  //     // onUpdate: (self) => {
  //     //   console.log(`Progress: ${self.progress}`);
  //     // },
  //   }
  // });

  // tl3.from('#section-3 h2', { opacity: 0, y: 20, transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)', duration: 0.3 })
  // .from('#section-3 .list-animate .item', { opacity: 0, y: 20, duration: 0.3, stagger: 0.2 });


// Function to generate a random position outside the center "safe zone"
function randomPosition(max, centerSafeZone) {
  let position;
  do {
    position = Math.floor(Math.random() * max);
  } while (position > centerSafeZone.min && position < centerSafeZone.max);
  return position;
}

// Animate the logos
function animateLogos() {
  const logos = document.querySelectorAll('#section-16 .logo');
  const section = document.querySelector('#section-16');
  const sectionWidth = section.clientWidth;
  const sectionHeight = section.clientHeight;

  const centerX = sectionWidth / 2;
  const centerY = sectionHeight / 2;

  logos.forEach((logo, index) => {
    // Set a random initial position outside the center area
    const angle = Math.random() * 2 * Math.PI;
    const distance = Math.max(sectionWidth, sectionHeight) / 2; // Ensure logos start from edges
    const startX = centerX + Math.cos(angle) * distance;
    const startY = centerY + Math.sin(angle) * distance;

    // Set initial position and make sure the logo is visible within the section
    gsap.set(logo, { 
      left: Math.max(0, Math.min(startX, sectionWidth - logo.offsetWidth)),
      top: Math.max(0, Math.min(startY, sectionHeight - logo.offsetHeight)),
      opacity: 1,
      // duration: 0.1 
    });

    // Create a timeline for each logo
    const tl = gsap.timeline({ 
      repeat: -1, 
      //repeatDelay: 0.2,
      delay: Math.random() * 2 // Random delay for each logo's animation start
    });
    console.log(centerY - (logo.offsetWidth * 0.7));

    tl.to(logo, {
      //opacity: 1,
      duration: 10,
      // x: centerX - logo.offsetWidth / 2,  // Center X (accounting for logo size)
      // y: centerY - logo.offsetHeight / 2, // Center Y (accounting for logo size)
      top: centerY - (logo.offsetWidth * 0.7),  // Center Y (accounting for logo size)
      left: centerX - logo.offsetHeight, // Center X (accounting for logo size)
      stagger: 0.3,
      ease: "power2.Out"
    })
    .set(logo, {
      opacity: 0,
      display: "none", // Hide logo after animation,
      delay: 3
    });
  });
}

// Call the animate function on page load
window.addEventListener('load', animateLogos);


const timelineItems = document.querySelectorAll('.timeline-item');
const contentItems = document.querySelectorAll('.timeline-slide .item');

timelineItems.forEach(item => {
  item.addEventListener('click', () => {
    const targetId = item.id.replace('toggle-', '');

    contentItems.forEach(content => {
      content.classList.remove('active');
      if (content.id === targetId) {
        content.classList.add('active');
      }
    });
  });
});



  // // Function to generate a random position within the width and height of the section
  // function getRandomPosition(range) {
  //   return Math.random() * range - range / 2; // Centered random distribution
  // }

  // const sectionWidth = section.offsetWidth;
  // const sectionHeight = section.offsetHeight;

  // // Animate logos from the center to random positions
  // gsap.fromTo(
  //   logos,
  //   {
  //     x: 0,
  //     y: 0,
  //     opacity: 0,
  //     scale: 0,
  //   },
  //   {
  //     x: () => getRandomPosition(sectionWidth),
  //     y: () => getRandomPosition(sectionHeight),
  //     opacity: 1,
  //     scale: 1,
  //     duration: 1.5,
  //     ease: 'power2.out',
  //     stagger: 0.1,
  //   }
  // );
  gsap.from('#section-0 #intro-hero-title', {
    opacity: 0,
    y: 20,
    transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)',
    duration: 0.3
  });

  

  // Initialize sound effect
  const soundEffect1 = new Howl({
    src: ['/touch1.wav'],
    volume: 0.4,  // Adjust the volume as needed
  });

  const soundEffect2 = new Howl({
    src: ['/touch2.wav'],
    volume: 0.4,  // Adjust the volume as needed
  });

  const soundEffect3 = new Howl({
    src: ['/touch3.wav'],
    volume: 0.4,  // Adjust the volume as needed
  });



  const sections = document.querySelectorAll('section');

  sections.forEach(section => {
    const listItems = section.querySelectorAll('.list-animate .item');
    //console.log(listItems);

    // Animate h2 if available
    const h2 = section.querySelector('h2');
    if (h2) {
      gsap.from(h2, {
        opacity: 0,
        y: 20,
        transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)',
        duration: 0.1,
        scrollTrigger: {
          trigger: section,
          scroller: "#app",
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
          //markers: true
        },
        onStart: () => {
          soundEffect3.play();
        }
      });
    }

    // Animate h3 if available
    const h3 = section.querySelector('h3');
    if (h3) {
      gsap.from(h3, {
        opacity: 0,
        y: 20,
        transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)',
        duration: 0.1,
        scrollTrigger: {
          trigger: section,
          scroller: "#app",
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
        // markers: true
        }
      });
    }

    // Animate p if available
    const p = section.querySelector('p');
    if (p) {
      gsap.from(p, {
        opacity: 0,
        y: 20,
        transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)',
        duration: 0.1,
        scrollTrigger: {
          trigger: section,
          scroller: "#app",
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
          //markers: true
        }
      });
    }

    // Animate p if available
    const span = section.querySelector('span');
    if (span) {
      gsap.from(span, {
        opacity: 0,
        y: 20,
        transform: 'translate3d(0px, 1em, 0px) rotateY(-20deg) rotateX(-60deg)',
        duration: 0.1,
        scrollTrigger: {
          trigger: section,
          scroller: "#app",
          start: 'top top',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
          //markers: true
        }
      });
    }

    if (listItems.length > 0) {
      console.log('animate list');
      // Create a unique animation for each section
      gsap.from(listItems,
        {
          opacity: 0,
          y: 20,
          duration: 1,
          stagger: 0.1,
          scrollTrigger: {
            trigger: section,
            scroller: "#app",
            start: 'top top', // Adjust start point as necessary
            end: 'bottom bottom', // Adjust end point as necessary
            toggleActions: 'play none none reverse',
            //markers: true // Enable markers for debugging
          }
        }
      );
    }


    const leaderboard = document.querySelectorAll('.leaderboard .item');
    if (leaderboard) {

      gsap.fromTo(leaderboard, 
        {
          scale: 0.6,
          //y: 100, // Start position at y = -50
        },
        {
          //y: 0, // Moves from 50px below
          scale: 1, // Starts at 80% of its size
          delay: 1,
          duration: 1, // Adjust the duration as needed
          stagger: 1,
          scrollTrigger: {
            trigger: section,
            scroller: "#app",
            start: 'top top',
            end: 'bottom bottom',
            toggleActions: 'play none none reverse',
            //markers: true
          },
        },
        {
          repeat: -1, // Infinite loop
          //yoyo: true,
          ease: 'power2.out', // Optional: Add easing for smooth movement
        }
      );

    }

 

  
  });

  // document.getElementById('scrollButton').addEventListener('click', function() {
  //   // Find the current section and determine the next section
  //   const currentSection = document.querySelector('#section-0');
  //   const nextSection = currentSection.nextElementSibling;
  
  //   // Scroll to the next section using GSAP
  //   if (nextSection) {
  //     gsap.to(window, { 
  //       duration: 1, 
  //       scrollTo: {
  //         y: nextSection, // Scroll to the next section
  //         offsetY: 0 // Optional: you can offset the scroll position if needed
  //       }, 
  //       ease: 'power2.inOut'
  //     });
  //   }
  // });

  
  
  window.addEventListener('load', () => {
    const preloader = document.getElementById('preloader');
    if (preloader) {
      preloader.style.display = 'none';
    }
  });


}